import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../images/v.png';
import Button from '../Button';
import { Link } from "gatsby";
import styled from "styled-components"

const HomeLink = styled(props => <Link {...props} />)`
  color: #1a232c;
  font-family: 'PT Sans';
  font-style: normal;
`;

const TagSpan = styled(props => <span {...props} />)`
  color: #1a232c;
  font-family: 'PT Sans';
  font-style: normal;
`;

const Header = () => (  
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">                
          <img src={LogoIcon}/>          
        </div>
        <HomeLink className="text-3xl sm:text-3xl" to="/">VUE</HomeLink>
        <TagSpan className="text-sm sm:text-sm ml-2 mb-2 mt-2 font-light">Search · Organize · Present</TagSpan>                    
      </div>
      <div className="flex mt-4 sm:mt-0 mr-10">
        <Link className="px-4" to="/about">
          About
        </Link>
        <Link className="px-4" to="/gallery">
          Gallery
        </Link>
        <Link className="px-4" to="/features">
          Features
        </Link>
        <Link to="/forums">
          Forums
        </Link>
        <Link className="px-4" to="/faq">
          FAQ
        </Link>
        <Link className="px-4" to="/docs">
          Documentation
        </Link>
        <Link className="px-4 mr-10" to="/contact">
          Contact
        </Link>        
        
      </div>    
    </div>
    <a className="github-fork-ribbon" href="https://github.com/VUE/VUE" data-ribbon="Fork me on GitHub" title="Fork me on GitHub">Fork me on GitHub</a>
  </header>
);

export default Header;
