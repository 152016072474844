import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-20 mb-8 text-gray-800">
    <div className="flex -mx-3">      
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Our Organization</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.tufts.edu">Tufts University</a>
          </li>
          <li>
            <a href="https://it.tufts.edu">Tufts Technology Services</a>
          </li>
          <li>
            <a href="https://it.tufts.edu/about/organization/educational-technology-and-learning-spaces">Educational Technology</a>
          </li>          
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">                    
          <li>
            <a href="https://github.com/VUE/VUE">Github</a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UC_mzN9PKwTYXJQFfgTm2otQ">Youtube</a>
          </li>
          
          <li>
            <a href="https://www.facebook.com/pages/category/Product-Service/VUE-38002625867/">Facebook</a>
          </li>        

        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Stats</h2>
        <ul className="mt-4 leading-loose">                    
          <li className="mb-4">
            <img src="https://img.shields.io/github/forks/VUE/VUE?style=social"/>
          </li>
          <li className="mb-4">
            <img src="https://img.shields.io/github/stars/VUE/VUE?style=social"/>
          </li>          
          <li className="mb-4">
            <img src="https://img.shields.io/github/watchers/VUE/VUE?style=social"/>
          </li>          
        </ul>
      </div>
      
    </div>    
    <div className="flex content-start flex-wrap">
      <div className="w-full p-1">
        <div className="text-gray-700 text-center">
        © 2020 Tufts University. 
        </div>
       </div>
    </div>
  </footer>

);

export default Footer;
